<template>
	<div id="home">
		<banner />
		<articles> </articles>
	</div>
</template>

<script>
export default {
	name: "Home",
	metaInfo() {
		return {
			meta: [
				{ name: "description", content: "Strona autorska Natasha Lucas. Romanse paranormalne, Daj się uwieść magicznemu światu romansów paranormalnych. Oszukana, Wojowniczka, Moja Bestia, Kusicielka. E-book, pdf, ksiazka" },
				{ property: "og:title", content: "Strona Główna| Natasha Lucas" },
				{ property: "og:description", content: "Strona autorska Natasha Lucas. Romanse paranormalne, Daj się uwieść magicznemu światu romansów paranormalnych. Oszukana, Wojowniczka, Moja Bestia, Kusicielka. E-book, pdf, ksiazka" },
				{ property: "og:url", content: "https://www.natashalucas.pl/" },
				{ property: "og:image", content: "https://www.natashalucas.pl/img/icons/natashaLucas.png" },
				{ name: "robots", content: "index,follow" },
			],
		};
	},

	beforeRouteEnter(to, from, next) {
		document.title = "Natasha Lucas - Strona Główna";
		next();
	},

	components: {
		Articles: () => import("@/components/home/Articles"),
		Banner: () => import("@/components/home/Banner"),
	},
};
</script>
